import {type ReactNode, useEffect, useState} from 'react';

let isHydrating = true;

interface Props {
  children(): ReactNode;
  fallback?: ReactNode;
}

export function ClientOnly({children, fallback = null}: Props) {
  const [isHydrated, setIsHydrated] = useState(() => !isHydrating);

  useEffect(() => {
    isHydrating = false;
    setIsHydrated(true);
  }, []);

  return isHydrated ? <>{children()}</> : <>{fallback}</>;
}
